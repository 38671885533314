import { Typography } from "antd";
import { Link } from "gatsby";
import * as React from "react";
import Page from "../components/page";

const { Title, Paragraph } = Typography;
const NotFoundPage = () => {
  return (
    <Page title="Not Found">
      <Title>ページが見つかりません</Title>
      <Paragraph>
        ページが移動または削除されたか、入力した URL
        が間違っている可能性があります。
      </Paragraph>
      <Paragraph>
        <Link to="/">ホームに戻る</Link>
      </Paragraph>
    </Page>
  );
};

export default NotFoundPage;
